<script lang="ts" setup>
	import { LoginResponse } from "~/server/routes/mock/auth/login.get";

	definePageMeta({
		layout: "public",
	});

	const email = ref<string>("");
	const success = ref<boolean>(false);

	const process = async function () {
		await record<LoginResponse>("auth/login", { email: email.value });
		success.value = true;
	};
</script>

<template>
	<div class="login">
		<div v-if="success" class="success panel border-grey shadow-large border-radius-normal">
			<div class="icon-holder border-grey">
				<icon-complete />
			</div>

			<h1>We've sent you an email</h1>
			<Button size="large" state="red" @click="success = false">Resend email</Button>
		</div>

		<div v-if="!success" class="panel border-grey shadow-large border-radius-normal">
			<logo />

			<div class="header">
				<h1>Sign in to your account</h1>
				<p class="large">We’ll send you a one-time link for you to log in.</p>
			</div>
			<InputForm :action="process" v-slot="{ form, processing, submit }" id="fields">
				<InputTextField :form="form" placeholder="Email" autocomplete="on" type="email" :required="true" v-model:value="email"></InputTextField>

				<Button size="large" :loading="processing" @click="submit">Continue</Button>
			</InputForm>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.login {
		display: flex;
		width: 100%;
		height: 100vh;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 30px;
		background: radial-gradient(72.29% 72.29% at 50% 0%, $red_semi 0%, rgba(246, 248, 254, 0) 100%);

		.success {
			display: flex;
			flex-direction: column;
			gap: 30px;
			align-items: center;
			.button {
				width: 220px;
			}
		}
		.panel {
			overflow: hidden;
			width: 460px;
			padding: 50px;
			display: flex;
			flex-direction: column;
			gap: 30px;

			.header {
				display: flex;
				flex-direction: column;
				gap: 4px;
			}

			.form {
				gap: 30px;
				.button {
					width: 100%;
				}
			}
		}
	}
</style>
